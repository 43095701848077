<template>
  <div>
    <b-col class="p-0">
      <b-card-actions
        ref="refFilter"
        :title="$t('Filters')"
        action-collapse
        :collapsed="collapsedFilter"
      >
        <b-card-body class="p-0">

          <b-row>
            <!-- Property -->
            <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
            >
              <label>{{ $t('Property') }}</label>
              <b-form-input
                :value="propertyFilter"
                class="d-inline-block mr-1"
                :reduce="val => val.value"
                @input="(val) => $emit('update:propertyFilter', val)"
              />
            </b-col>

            <!-- Value -->
            <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
            >
              <label>{{ $t('Value') }}</label>
              <b-form-input
                :value="valueFilter"
                class="d-inline-block mr-1"
                :reduce="val => val.value"
                @input="(val) => $emit('update:valueFilter', val)"
              />
            </b-col>

            <!-- Type -->
            <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
            >
              <label>{{ $t('Type') }}</label>
              <v-select
                :value="typeFilter"
                :options="typeOptions"
                :clearable="true"
                class="w-100"
                :reduce="val => val.value"
                @input="(val) => $emit('update:typeFilter', val)"
              />
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <!-- Button -->
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
              style="text-align: right;"
            >
              <div>
                <b-button
                  variant="outline-primary"
                  @click="resetFilter"
                >
                  {{ `${$t('Reset Filter')}` }}
                </b-button>
                &nbsp;
                <b-button
                  variant="info"
                  @click="onCollapsed"
                >
                  {{ `${$t('Collapse')}` }}
                </b-button>
              </div>
            </b-col>
          </b-row>

        </b-card-body>
      </b-card-actions>
    </b-col>
  </div>
</template>

<script>
import {
  BCardBody, BRow, BCol,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCardBody,
    BCardActions,
    BFormInput,
    BButton,

    vSelect,
  },
  props: {
    collapsedFilter: {
      type: Boolean,
      default: false,
    },
    propertyFilter: {
      type: [String, null],
      default: null,
    },
    valueFilter: {
      type: [String, null],
      default: null,
    },
    typeFilter: {
      type: [String, null],
      default: null,
    },
    typeOptions: {
      type: Array,
      required: true,
    },
  },
  methods: {
    resetFilter() {
      this.$emit('reset-filter')
    },
    onCollapsed() {
      this.$refs.refFilter.triggerCollapse()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.card {
  .card-title {
    font-size: 1.07rem;
  }
  .card-header {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
</style>
